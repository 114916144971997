import "../_scss/main.scss";
import reveal from "reveal.js";
reveal.initialize({
    controls: true,
    progress: true,
    history: true,
    center: true,
    hash: true,
    transition: "slide",
    width: "80%"
});